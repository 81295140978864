$background_color: #fbf7f5;

$primary_color: #2b2b2b;
$secondary_color: #6b717f;

$anchor_color: #2b2b2b;
$anchor_color_hover: #4b4b4b;
$anchor_color_underline: #ababab;

$primary_font: Avenir, 'Avenir Next LT Pro', Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$secondary_font: Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$content_max_width: 35rem;
$content_centered: 0 auto;



@font-face {
    font-family: 'Patua One';
    src: url('/assets/fonts/PatuaOne-Regular.woff?v=1.0'); }



 :root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $secondary_font;
    padding: 2rem;
    background-color: $background_color;
    overflow-x: hidden; }



h1, h2, h3, h4, h5, h6 {
    font-family: $primary_font;
    letter-spacing: -0.05rem; }

p {
    line-height: 1.4rem;
    letter-spacing: -0.01rem; }

a {
    color: $anchor_color;
    line-height: 1.75rem;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: $anchor_color_underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 0.125rem;
    text-underline-offset: 0.3rem;
    transition: 0.25s all ease-in-out;

    &:hover, &:active {
        text-decoration-color: $anchor_color; } }


div.highlight {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-left: 0.3rem solid #898989;
    overflow-x: auto; }


.wrapper {
    max-width: $content_max_width;
    margin: $content_centered; }


header {
    padding: 1rem 0rem 2rem;

    a:hover, a:active {
        color: $anchor_color_hover; }

    h2 {
        display: inline-block;
        margin: 0.5rem 1rem 0.5rem 0rem;

        a {
            color: $primary_color;
            text-decoration: none;
            font-weight: bold; } }

    a:hover, a:active {
        text-decoration: none; }

    nav {
        display: inline-block;
        margin: 0.5rem 0rem 0.5rem 0rem;

        li {
            display: inline-block;
            margin-right: 0.2rem;

            &:last-child {
                margin-right: 0rem; } }


        a {
            color: $secondary_color;
            text-decoration: none;
            font-weight: normal;

            &:hover, &:active {
                color: {} }

            &.highlight {
                color: $primary_color;
                font-weight: bold;
                background: none !important; } } } }



main {
    margin: 1rem 0rem 1rem 0rem; }


footer {
    padding: 3rem 0rem 1rem; }
